import { useRouter } from "next/router";
import styles from "./Tutorial.module.scss";
import cn from "classnames";
import Image from "next/image";
import arrow from "../../public/images/long-arrow-right.svg";
import { useEffect, useState } from "react";
import { Button } from "../button/Button";
import config from "../../utils/config";

export function Tutorial({}) {
  const [visible, setVisible] = useState(false);
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState([
    {
      id: 0,
      text: `Bienvenue sur ${config.brandName} !\n\nVos identifiants de connexion sont les mêmes que sur France Barter. Cliquez en haut à droite pour vous connecter.\n\nVous pouvez également générer un nouveau mot de passe avec votre mail sur ce lien.`,
      arrowRotation: -20,
      focusAreaLocation: {
        top: 20,
        right: 295,
        left: undefined,
      },
      focusAreaSize: {
        width: 140,
        height: 40,
      },
    },
    {
      id: 1,
      text: "Vous pouvez aussi laisser vos coordonnées dans le chat afin de recevoir un lien de connexion.",
      arrowRotation: 50,
      focusAreaLocation: {
        bottom: 7,
        right: 12,
      },
      focusAreaSize: {
        width: 85,
        height: 85,
      },
    },
    {
      id: 2,
      text: "Mettez à jour votre fiche entreprise",
      arrowRotation: -30,
      focusAreaLocation: {
        top: 112,
        right: 70,
      },
      focusAreaSize: {
        width: 250,
        height: 45,
      },
    },
    {
      id: 3,
      text: "Ajouter des contacts pour gérer le compte",
      arrowRotation: -30,
      focusAreaLocation: {
        top: 162,
        right: 120,
      },
      focusAreaSize: {
        width: 200,
        height: 45,
      },
    },
    {
      id: 4,
      text: "Pour bien débuter nous vous invitons à publier une offre",
      arrowRotation: -140,
      focusAreaLocation: {
        top: 5,
        left: 165,
      },
      focusAreaSize: {
        width: 250,
        height: 70,
      },
    },
    {
      id: 5,
      text: "Éditez vos offres",
      arrowRotation: -30,
      focusAreaLocation: {
        top: 212,
        right: 70,
      },
      focusAreaSize: {
        width: 250,
        height: 45,
      },
    },
    {
      id: 6,
      text: "Découvrez les offres disponibles sur la plateforme",
      arrowRotation: -30,
      focusAreaLocation: {
        top: 485,
        left: 0,
      },
      focusAreaSize: {
        width: 310,
        height: 50,
      },
    },
    {
      id: 7,
      text: "Pour chercher des offres des membres, vous pouvez utiliser le moteur de recherche et/ou les filtres",
      arrowRotation: -140,
      focusAreaLocation: {
        top: 340,
        left: 0,
      },
      focusAreaSize: {
        width: 160,
        height: 30,
      },
    },
    {
      id: 8,
      text: "Décrivez un besoin et recevez une proposition",
      arrowRotation: -30,
      focusAreaLocation: {
        top: 248,
        right: 0,
      },
      focusAreaSize: {
        width: 270,
        height: 60,
      },
    },
    {
      id: 9,
      text: `Consultez votre compte ${config.brandName}`,
      arrowRotation: -30,
      focusAreaLocation: {
        top: 262,
        right: 70,
      },
      focusAreaSize: {
        width: 250,
        height: 45,
      },
    },
    {
      id: 10,
      text: `Participez aux évènements ${config.brandName} :`,
      arrowRotation: -30,
      focusAreaLocation: {
        top: 250,
        right: 50,
        left: 0,
      },
      focusAreaSize: {
        width: 0,
        height: 0,
      },
    },
  ]);
  const router = useRouter();

  useEffect(() => {
    const onResize = () => {
      setSteps((oldSteps) =>
        oldSteps.map((x, index) => {
          if (index === 6) {
            x.focusAreaLocation.right = window.innerWidth / 2 - 155;
          } else if (index === 7) {
            x.focusAreaLocation.left = window.innerWidth / 2 - 535;
          } else if (index === 8) {
            x.focusAreaLocation.right = window.innerWidth / 2 - 135;
          }
          return x;
        })
      );
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  useEffect(() => {
    setSteps((oldSteps) =>
      oldSteps.map((x, index) => {
        if (index === 6) {
          x.focusAreaLocation.right = window.innerWidth / 2 - 155;
        } else if (index === 7) {
          x.focusAreaLocation.left = window.innerWidth / 2 - 505;
        } else if (index === 8) {
          x.focusAreaLocation.right = window.innerWidth / 2 - 135;
        } else if (index === 10) {
          x.focusAreaLocation.right = window.innerWidth / 2 - 375;
        }
        return x;
      })
    );
  }, []);

  useEffect(() => {
    if (router && router.query && router.query.tutorial) {
      setVisible(true);
      setStep(parseInt(router.query.tutorial as string));
    } else {
      setStep(0);
      setVisible(false);
    }
  }, [router]);

  const getCurrentStep = () => {
    console.log(steps[step])
    return steps[step];
  };

  const getPosition = () => {
    if (
      getCurrentStep().arrowRotation < 0 &&
      getCurrentStep().arrowRotation > -90
    ) {
      return "topRight";
    } else if (
      getCurrentStep().arrowRotation >= 0 &&
      getCurrentStep().arrowRotation < 90
    ) {
      return "bottomRight";
    } else if (
      getCurrentStep().arrowRotation <= -90 &&
      getCurrentStep().arrowRotation > -180
    ) {
      return "topLeft";
    } else if (
      getCurrentStep().arrowRotation >= 90 &&
      getCurrentStep().arrowRotation < 180
    ) {
      return "bottomLeft";
    }
  };

  const onNextStep = () => {
    if (step === steps.length - 1) {
      router.push(`/offers`);
    } else if (step >= 1) {
      router.push(`/offers?tutorial=${step + 1}`);
    } else {
      router.push(`/home?tutorial=${step + 1}`);
    }
  };

  return (
    <>
      {visible && (
        <div className={styles.container}>
          <div
            style={{
              top: getCurrentStep().focusAreaLocation.top
                ? `${
                    getCurrentStep().focusAreaLocation.top +
                    30 +
                    getCurrentStep().focusAreaSize.height
                  }px`
                : "initial",
              left: getCurrentStep().focusAreaLocation.left
                ? `${
                    getCurrentStep().focusAreaLocation.left +
                    100 +
                    getCurrentStep().focusAreaSize.width
                  }px`
                : "initial",
              right: getCurrentStep().focusAreaLocation.right
                ? `${
                    getCurrentStep().focusAreaLocation.right +
                    130 +
                    getCurrentStep().focusAreaSize.width
                  }px`
                : "initial",
              bottom: getCurrentStep().focusAreaLocation.bottom
                ? `${
                    getCurrentStep().focusAreaLocation.bottom +
                    50 +
                    getCurrentStep().focusAreaSize.height
                  }px`
                : "initial",
            }}
            className={styles.text__container}
          >
            {visible && getCurrentStep().id != 10 && (
            <div
              style={{
                transform: `rotate(${getCurrentStep().arrowRotation}deg)`,
              }}
              className={cn({
                [styles.focus__arrow__container]: true,
                [styles.top__right]: getPosition() === "topRight",
                [styles.bottom__right]: getPosition() === "bottomRight",
                [styles.top__left]: getPosition() === "topLeft",
                [styles.bottom__left]: getPosition() === "bottomLeft",
              })}
            >
              <Image
                width={150}
                height={100}
                src={arrow}
                className={styles.focus__arrow}
              />
            </div>
            )}
            <p className={styles.text}>{getCurrentStep().text}</p>
            {visible && getCurrentStep().id == 10 && (
                <div className={styles.link}
                     onClick={() =>
                         window.open('https://www.recette.franceactive.korp.co/event')
                     }
                   >
                  Voir les évènements
                </div>
            )}
            <Button
              className={styles.next__button}
              text={step < steps.length - 1 ? "Suivant" : "Terminer"}
              theme="primary"
              onClick={onNextStep}
            />
          </div>
          <div
            style={{
              width: `${getCurrentStep().focusAreaSize.width}px`,
              height: `${getCurrentStep().focusAreaSize.height}px`,
              top: getCurrentStep().focusAreaLocation.top
                ? `${getCurrentStep().focusAreaLocation.top}px`
                : "initial",
              left: getCurrentStep().focusAreaLocation.left
                ? `${getCurrentStep().focusAreaLocation.left}px`
                : "initial",
              right: getCurrentStep().focusAreaLocation.right
                ? `${getCurrentStep().focusAreaLocation.right}px`
                : "initial",
              bottom: getCurrentStep().focusAreaLocation.bottom
                ? `${getCurrentStep().focusAreaLocation.bottom}px`
                : "initial",
            }}
            className={styles.focus__content__wrapper}
          >
            <div className={styles.focus__content}></div>
          </div>
        </div>
      )}
    </>
  );
}
