import Head from "next/head";
import { BgTitle } from "../components/bg-title/BgTitle";
import { Button } from "../components/button/Button";
import { Header } from "../components/header/Header";
import styles from "../styles/Home.module.scss";
import cn from "classnames";
import { MainTemplate } from "../templates/main-template";
import useOnScreen, {
  useInterval,
  useMobileMode,
  useOnScroll,
  useSectors,
} from "../utils/hooks";
import { useEffect, useRef, useState } from "react";
import { RollingText } from "../components/rolling-text/RollingText";
import { RollingLogos } from "../components/rolling-logos/RollingLogos";
import { useRouter } from "next/router";
import { RollingOffers } from "../components/rolling-offers/RollingOffers";
import { RoundButton } from "../components/round-button/RoundButton";
import { Slider } from "../components/slider/Slider";
import { Input } from "../components/input/Input";
import { SelectBox } from "../components/select-box/SelectBox";
import { Tutorial } from "../components/tutorial/Tutorial";
import config from "../utils/config";

export default function Home({
  title,
  subtitle,
  buttonText,
  customer,
  slogan,
  explanations,
  image,
  buttonLink,
}) {
  const isMobile = useMobileMode();
  const router = useRouter();
  const [headerShadow, setHeaderShadow] = useState(false);
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0);

  useOnScroll(() => {
    if (window.scrollY > 10) {
      setHeaderShadow(true);
    } else {
      setHeaderShadow(false);
    }
  });

  const onAskForDemo = () => {
    window.open(config.calendlyHref);
  };

  return (
    <MainTemplate headerShadow={headerShadow}>
      <div className={styles.container}>
        <Tutorial />
        <div className={styles.content}>

{/* TROC ACTIF */}

          <section className={styles.cover}>
            <div className={styles.cover__content}>
              <div className={styles.cover__left__pane}>
                {title && <h1 className={styles.cover__title}>{title}</h1>}
                {!title && (
                  <h1 className={styles.cover__title}>{config.brandName}</h1>
                )}
                <div className={styles.cover__separator}></div>
                {subtitle ? (
                  <p className={styles.cover__subtitle}>{subtitle}</p>
                ) : (
                  <p className={styles.cover__subtitle}>
                    La première plateforme engagée<br/>de troc inter-entreprises.
                  </p>
                )}
                <div className={styles.cover__buttons}>
                  <Button
                    onClick={onAskForDemo}
                    text="Démo"
                    className={styles.cover__button}
                    theme="deepBlue"
                  />
                  <Button
                    onClick={() =>
                      buttonLink
                        ? window.open(buttonLink)
                        : router.push("/signup")
                    }
                    className={styles.cover__button}
                    text={buttonText ? buttonText : "Je m'inscris"}
                    theme="white"
                  />
                </div>
              </div>
              <div className={styles.cover__right__pane}>
                <div className={styles.cover__video}>
                  <iframe width="640" height="400" src="https://www.youtube.com/embed/xgUW1A0faq8"
                          title="Troc Actif - La plateforme engagée de troc inter-entreprises" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </section>

{/* AVANTAGES */}

          <section className={styles.explanation__section}>
            <div className={styles.explanation__content}>
              <div className={styles.explanation__title}>
                les avantages de troc actif
              </div>
              <div className={styles.explanation__grid}>
                    <div className={styles.explanation__card}>
                      <img
                        className={styles.explanation__card__image}
                        src="/images/image-1.png"
                      />
                      <p className={styles.explanation__card__number}>
                        économique
                      </p>
                      <p className={styles.explanation__card__title}>
                        <b>Préservez votre trésorerie</b> grâce à des paiements par compensation.
                      </p>
                    </div>
                    <div className={styles.explanation__card}>
                      <img
                        className={styles.explanation__card__image}
                        src="/images/image-2.png"
                      />
                      <p className={styles.explanation__card__number}>
                        social
                      </p>
                      <p className={styles.explanation__card__title}>
                        <b>Rencontrez d'autres entrepreneur.e.s</b> lors d'évènements locaux et régionaux.
                      </p>
                    </div>
                    <div className={styles.explanation__card}>
                      <img
                        className={styles.explanation__card__image}
                        src="/images/image-3.png"
                      />
                      <p className={styles.explanation__card__number}>
                        territorial
                      </p>
                      <p className={styles.explanation__card__title}>
                        <b>Développez votre maillage territorial</b> en faveur de la consommation locale.
                      </p>
                    </div>
                    <div className={styles.explanation__card}>
                      <img
                        className={styles.explanation__card__image}
                        src="/images/image-4.png"
                      />
                      <p className={styles.explanation__card__number}>
                        environnemental
                      </p>
                      <p className={styles.explanation__card__title}>
                        <b>Favorisez les circuits courts</b> et réduisez les distances de transport parcourues.
                      </p>
                    </div>
              </div>
            </div>
          </section>

{/* MULTI-IMPACT */}

          <section className={styles.features__section}>
            <div className={styles.features__content}>
              <div className={styles.features__left__pane}>
                <h1 className={styles.features__title}>{config.brandName},<br/>c'est quoi ?</h1>
                <p className={styles.features__subtitle}>
                  Troc commercial mutlilatéral qui permet aux
                  entreprises d'échanger des biens et des services
                  en limitant leur sortie de trésorerie grâce à une
                  unité de compte de référence.
                </p>
                <div className={styles.features__buttons}>
                  <Button
                      onClick={() =>
                          buttonLink
                              ? window.open(buttonLink)
                              : router.push("/projet#adviser")
                      }
                    text="en savoir plus"
                    className={styles.features__button}
                    theme="deepBlue"
                  />
                  <Button
                    onClick={onAskForDemo}
                    text="Démo"
                    className={styles.features__button}
                    theme="white"
                  />
                </div>
              </div>
              <div className={styles.features__right__pane}>
                <img
                  className={styles.features__right__pane__image}
                  src="/images/troc-actif.png"
                />
              </div>
            </div>
          </section>



          <section className={styles.begin__section}>
            <div className={styles.begin__content}>
              <div className={styles.begin__left__pane}>
                <p className={styles.begin__title}>
                  comment ça marche ?
                </p>
                <div className={styles.begin__grid}>
                      <div className={styles.begin__card}>
                        <img
                          className={styles.begin__card__image}
                          src="/images/element-1.png"
                        />
                        <p className={styles.begin__card__number}>
                          01
                        </p>
                        <p className={styles.begin__card__subtitle}>
                          Je m'inscris gratuitement à la plateforme Troc Actif.
                        </p>
                      </div>
                      <div className={styles.begin__card}>
                        <img
                          className={styles.begin__card__image}
                          src="/images/element-2.png"
                        />
                        <p className={styles.begin__card__number}>
                          02
                        </p>
                        <p className={styles.begin__card__subtitle}>
                          Je renseigne mes offres et mes besoins en 100% crédit Actif en fixant le prix au prix du marché.
                        </p>
                      </div>
                      <div className={styles.begin__card}>
                        <img
                          className={styles.begin__card__image}
                          src="/images/element-3.png"
                        />
                        <p className={styles.begin__card__number}>
                          03
                        </p>
                        <p className={styles.begin__card__subtitle}>
                          Je dispose alors d'un compte Actif.<br/>J'achète et je vends en 100% Actif.
                        </p>
                      </div>
                      <div className={styles.begin__card}>
                        <img
                          className={styles.begin__card__image}
                          src="/images/element-4.png"
                        />
                        <p className={styles.begin__card__number}>
                          04
                        </p>
                        <p className={styles.begin__card__subtitle}>
                          Lors d'une vente ou d'un achat mon compte est crédité ou débité de la valeur du bien ou du service exprimé en valeur crédit actif.
                        </p>
                      </div>
                </div>
              </div>
            </div>
          </section>



          <section className={styles.help__section}>
            <div className={styles.help__content}>
              <p className={styles.help__title}>
                des difficultés<br/>pour s'inscrire ?
              </p>
              <div className={styles.help__buttons}>
                <Button
                    onClick={onAskForDemo}
                  className={styles.help__button}
                  text={buttonText ? buttonText : 'je contacte un conseiller'}
                  theme="deepBlue"
                />
              </div>
            </div>
          </section>



          <section className={styles.network__section}>
{/*
            <div className={styles.network__filter}></div>
*/}
            <div className={styles.network__content}>
                <div className={styles.network__grid}>
                  <div className={styles.network__card}>
                    <p className={styles.network__title}>le réseau france active en <br /> île-de-france</p>
                    <p className={styles.network__description}>
                      Le mouvement des entrepreneur.e.s engagé.e.s francilien.ne.s <br/>
                      Notre objectif est de vous permettre de vous investir au maximum dans vos projets à impact
                      via des programmes d'accompagnements et de financements complets, adaptables, et évolutifs.
                    </p>
                    <div className={styles.network__buttons}>
                      <Button
                          onClick={() =>
                              buttonLink
                                  ? window.open(buttonLink)
                                  : router.push("/about")
                          }                      text="En savoir plus"
                      className={styles.network__button}
                      theme="deepBlue"
                    />
                    </div>
                  </div>
                </div>
            </div>
          </section>
        </div>
      </div>
    </MainTemplate>
  );
}
